import React, { useEffect, useState } from "react"
import SVGIcon from "../common/SVGIcon"
import { pageScroller, scrollPosition } from "../utility/scroll-position"

let WebinarDropDown = props => {
  const [scrollValue, setScrollValue] = useState()
  const { allTags, mainCategory } = props
  const mainCategories = props.mainCategories
  const [screenWidth, setScreenWidth] = useState(0)
  const [mainCategoryDrpDown, setMainCategoryDrpDown] = useState({
    Product: false,
    Thought_Leadership: false,
  })

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    let arr = [1, 2]
    arr.map(item => {
      const dropdownDiv = document.querySelector(`#sub_tag_${item}`)
      if (dropdownDiv) {
        const toggledNavbar = document
          .querySelector(`#inner_subtag_${item}`)
          ?.getBoundingClientRect()
        dropdownDiv.style.height = `${toggledNavbar.height}px`
      }
    })
  })

  useEffect(() => {
    setScrollValue(
      scrollPosition(
        ".track-category",
        screenWidth < 500 ? ".brand-nav-light-mob" : ".brand-nav-light",
        screenWidth < 500 ? 40 : 140
      )
    )
  }, [screenWidth])

  const MainCategoryDrpDownFn = item => {
    let tempObj = { ...mainCategoryDrpDown }
    mainCategory(item)
    if (item === "Product") {
      if (tempObj["Thought_Leadership"]) {
        tempObj["Thought_Leadership"] = false
      }
      tempObj["Product"] = !tempObj["Product"]
    } else {
      if (tempObj["Product"]) {
        tempObj["Product"] = false
      }
      tempObj["Thought_Leadership"] = !tempObj["Thought_Leadership"]
    }
    setMainCategoryDrpDown(tempObj)
  }

  const closeAllDropdowns = () => {
    let tempObj = { ...mainCategoryDrpDown }
    tempObj["Product"] = false
    tempObj["Thought_Leadership"] = false
    setMainCategoryDrpDown(tempObj)
  }

  return (
    <div className="categories">
      <div className="taglist-desk">
        <div className="alt">
          {/* <p className="font-roboto fw-600 mb48">CATEGORIES</p> */}
          <p className="p20 font-zodiak fw-bold mb48">On-demand webinar</p>
          <div
            className={`tagName cursor-pointer ${
              props.currentTag === "all" ? "active text-pink" : ""
            }`}
          >
            <p
              onClick={() => {
                props.selectTag("all")
                pageScroller(scrollValue)
              }}
              className="font-zodiak fw-bold"
            >
              All
            </p>
          </div>
          <div>
            {mainCategories?.map((item, id) => {
              return (
                <>
                  <div
                    className={`d-flex justify-content-between align-items-center cursor-pointer mb24 mt40 ${
                      mainCategoryDrpDown[item.replace(" ", "_")]
                        ? "underline"
                        : ""
                    }`}
                    onClick={() => MainCategoryDrpDownFn(item)}
                  >
                    <p className="font-zodiak fw-bold m-0">{item}</p>
                    <SVGIcon
                      name="arrow-down"
                      className={`down-arrow ${
                        mainCategoryDrpDown[item.replace(" ", "_")]
                          ? "active"
                          : ""
                      }`}
                    />
                  </div>
                  {
                    <div
                      id={`sub_tag_${id + 1}`}
                      className={`sub-tags ${
                        mainCategoryDrpDown[item.replace(" ", "_")]
                          ? ""
                          : "dspnone"
                      }`}
                    >
                      <div id={`inner_subtag_${id + 1}`}>
                        {allTags.map(tag => {
                          return (
                            tag.name !== "coming soon" &&
                            tag.slug === item && (
                              <div
                                className={`tagName cursor-pointer ${
                                  props.currentTag === tag.name ? "active" : ""
                                }`}
                              >
                                <p
                                  onClick={() => {
                                    props.selectTag(tag.name)
                                    pageScroller(scrollValue)
                                    screenWidth < 500 && closeAllDropdowns()
                                  }}
                                >
                                  {tag.name}
                                </p>
                              </div>
                            )
                          )
                        })}
                      </div>
                    </div>
                  }
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WebinarDropDown
